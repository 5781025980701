var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "mr-3",
                    staticStyle: { "font-size": "1.6rem" },
                  },
                  [_c("i", { staticClass: "flaticon2-calendar-2" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return [_c("MarkingPeriodSubHeaderMenu")]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "kt-container kt-grid__item kt-grid__item--fluid",
          on: {
            dragleave: _vm.onDragLeave,
            dragover: _vm.onDragOver,
            drop: _vm.onDrop,
          },
        },
        [
          _vm.showDropZone
            ? _c("div", { staticClass: "w-100" }, [
                _c(
                  "a",
                  { staticClass: "kt-header__brand-logo w-100 text-center" },
                  [
                    _c("SVGIcon", {
                      staticClass: "logo loading",
                      attrs: { name: "dropZone" },
                    }),
                    _c("h1", { staticClass: "kt-font-bolder" }, [
                      _vm._v(" Drop files here to upload! "),
                    ]),
                  ],
                  1
                ),
              ])
            : [
                _c("div", { key: `key_${_vm.key}`, staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: [
                        _vm.errorFiles.length == 0
                          ? "col-12"
                          : "col-xl-6 col-lg-6 col-md-6",
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "kt-portlet kt-portlet--height-fluid" },
                        [
                          _vm._m(0),
                          _vm.loading
                            ? _c(
                                "div",
                                { staticClass: "kt-portlet__body" },
                                [_vm.loading ? _c("PortletLoader") : _vm._e()],
                                1
                              )
                            : _c("div", { staticClass: "kt-portlet__body" }, [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    id: "file-input",
                                    type: "file",
                                    name: "file",
                                    accept: ".xls",
                                    multiple: "",
                                  },
                                  on: { change: _vm.onFileBrowseSelect },
                                }),
                                _c("div", { staticClass: "form-group pt-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.triggerFileBrowser.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Choose Files",
                                          readonly: "readonly",
                                        },
                                      }),
                                      _vm._m(1),
                                    ]
                                  ),
                                ]),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm.errorFiles.length !== 0
                    ? _c(
                        "div",
                        { staticClass: "col-xl-6 col-lg-6 col-md-6" },
                        [
                          _c("ErrorBin", {
                            attrs: { "error-files": _vm.errorFiles },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.showDataSystem("STARS Admin")
                        ? _c("DataSystemStarsExcel", {
                            ref: "dataSystemStarsAdmin",
                            attrs: { name: "STARS Admin", extension: "xls" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" File Uploader "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v(" Browse ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }