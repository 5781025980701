<template>
<div :key="`key_${key}`">
    <GeneralSubHeader>
        <template #title>
            <span
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                <i class="flaticon2-calendar-2" />
            </span>
        </template>
        <template #left-buttons>
            <MarkingPeriodSubHeaderMenu />
        </template>
        <template #buttons />
    </GeneralSubHeader>
    <div
        class="kt-container kt-grid__item kt-grid__item--fluid"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
        @drop="onDrop"
    >
        <div
            v-if="showDropZone"
            class="w-100"
        >
            <a class="kt-header__brand-logo w-100 text-center">
                <SVGIcon
                    name="dropZone"
                    class="logo loading"
                />

                <h1 class="kt-font-bolder">
                    Drop files here to upload!
                </h1>
            </a>
        </div>

        <template v-else>
            <div
                :key="`key_${key}`"
                class="row"
            >
                <div :class="[ errorFiles.length == 0 ? 'col-12' : 'col-xl-6 col-lg-6 col-md-6']">
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    File Uploader
                                </h3>
                            </div>
                        </div>

                        <div
                            v-if="loading"
                            class="kt-portlet__body"
                        >
                            <PortletLoader v-if="loading" />
                        </div>
                        <div
                            v-else
                            class="kt-portlet__body"
                        >
                            <input
                                id="file-input"
                                type="file"
                                name="file"
                                style="display: none"
                                accept=".xls"
                                multiple
                                @change="onFileBrowseSelect"
                            >

                            <div class="form-group pt-4">
                                <div
                                    class="input-group"
                                    style="cursor: pointer"
                                    @click.stop.prevent="triggerFileBrowser"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Choose Files"
                                        readonly="readonly"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            Browse
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="errorFiles.length !== 0"
                    class="col-xl-6 col-lg-6 col-md-6"
                >
                    <ErrorBin :error-files="errorFiles" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <DataSystemStarsExcel
                        v-if="showDataSystem('STARS Admin')"
                        ref="dataSystemStarsAdmin"
                        name="STARS Admin"
                        extension="xls"
                    />
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../store/Types';
import PortletLoader from '../components/PortletLoader.vue';
import DataSystemStarsExcel from '../components/uploads/DataSystemStarsExcel.vue';
import ErrorBin from '../components/uploads/ErrorBin.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import MarkingPeriodSubHeaderMenu from '../components/MarkingPeriodSubHeaderMenu.vue';

import * as util from '../lib/uploads/reportCard';
import * as network from '../network';

export default {
    name: 'UploadReportCards',
    components: {
        MarkingPeriodSubHeaderMenu,
        GeneralSubHeader,
        DataSystemStarsExcel,
        ErrorBin,
        PortletLoader,
    },
    data() {
        return {
            key: 1,
            dragTimer: null,
            loading: false,
            showDropZone: false,
            files: [],
            errorFiles: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
            dataSystems: (state) => state.database.dataSystems,
        }),
    },
    mounted() {
        const v = this;
        v.loadErrorFiles();

        v.$store.dispatch(Types.actions.REFRESH_MARKING_PERIODS, (err) => {
            if (err) return v.showError(err);
            v.key += 1;
        });
    },
    methods: {
        loadErrorFiles() {
            const v = this;
            const { schoolId, schoolTermId } = this.user.school;
            const params = {
                url: { schoolId, schoolTermId },
            };
            v.errorFiles = [];
            network.storage.getErrorBin(params, (err, res) => {
                if (err) return v.showError(err);
                v.errorFiles = res.files.map((f) => {
                    const file = f;
                    file.relativeDate = moment(f.fileUploaded).fromNow();
                    return file;
                });
                v.key += 1;
            });
        },
        loadFiles(files) {
            const v = this;
            this.hideDropZone();
            if (v.loading) return;
            v.loading = true;
            const markingPeriods = this.$store.state.database.markingPeriods.map((mp) => mp.markingPeriod);
            util.process(files, v.dataSystems, v.user, markingPeriods, (err) => {
                v.loading = false;
                if (err) return v.showError(err);
                window.location.reload();
            });
        },
        onFileBrowseSelect(e) {
            const files = Array.from(e.target.files);
            this.loadFiles(files);
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        showDataSystem(name) {
            return Boolean(this.dataSystems.find((c) => c.dataSystemName == name));
        },
        // drag drop stuff
        onDragOver(e) {
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            this.showDropZone = (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files')));
        },
        onDragLeave(e) {
            e.stopPropagation();
            e.preventDefault();
            this.hideDropZone();
        },
        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files);

            this.loadFiles(files);
        },
        hideDropZone() {
            const v = this;
            if (v.dragTimer) clearTimeout(v.dragTimer);
            v.dragTimer = setTimeout(() => {
                v.showDropZone = false;
            }, 25);
        },

    },
};
</script>

<style scoped>

a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    color: #0f88ef;
    font-size: 2.8rem;
    opacity: 0.4;
}

</style>
