<template>
<b-modal
    ref="student-guardian-modal"
    size="xl"
    variant="primary"
    centered
>
    <template #modal-header="{ close }">
        <h5
            class="modal-title"
        >
            {{ title }}
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <form
            v-if="guardian.studentGuardianId"
            class="kt-portlet__body p-3"
            @keyup.enter.prevent="save()"
            @submit.prevent
        >
            <div class="form-group row">
                <h3 class="kt-section__title col-12">
                    Guardian Biographical Information
                </h3>

                <div class="col-3">
                    <label class="">First Name *</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <select
                                class="form-control kt-selectpicker"
                                @change="guardianTitleChange($event)"
                            >
                                <option
                                    v-for="title in titles"
                                    :key="`title_${title}`"
                                    :value="title"
                                    :selected="title == guardian.guardianTitle"
                                >
                                    {{ title }}
                                </option>
                            </select>
                        </div>
                        <input
                            ref="firstName"
                            v-model="guardian.guardianFirstName"
                            type="text"
                            class="form-control"
                            :disabled="guardian.studentGuardianId != '0'"
                            :class="{'is-invalid': guardian.guardianFirstNameError}"
                            placeholder="Guardian First Name"
                            autofocus
                        >
                        <div
                            v-if="guardian.guardianFirstNameError"
                            class="invalid-feedback"
                        >
                            {{ guardian.guardianFirstNameError }}
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <label>Last Name *</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="la la-user" /></span>
                        </div>
                        <input
                            v-model="guardian.guardianLastName"
                            type="text"
                            class="form-control"
                            :disabled="guardian.studentGuardianId != '0'"
                            :class="{'is-invalid': guardian.guardianLastNameError}"
                            placeholder="Guardian Last Name"
                        >
                        <div
                            v-if="guardian.guardianLastNameError"
                            class="invalid-feedback"
                        >
                            {{ guardian.guardianLastNameError }}
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <label class="">Authorization *</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="flaticon flaticon-security" /></span>
                        </div>
                        <select
                            class="form-control kt-selectpicker"
                            @change="authorizationChange($event)"
                        >
                            <option
                                v-for="option in authorizationCodes"
                                :key="`authorizationCode_${option.code}`"
                                :value="option.value"
                                :selected="option.selected"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-3">
                    <label>Relation To Student</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="la la-users" /></span>
                        </div>
                        <select
                            class="form-control kt-selectpicker"
                            @change="relationChange($event)"
                        >
                            <option
                                v-for="option in relationCodes"
                                :key="`relation_${option.code}`"
                                :value="option.value"
                                :selected="option.selected"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-3">
                    <label class="">Oral Language</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="flaticon2 flaticon2-world" /></span>
                        </div>
                        <select
                            class="form-control kt-selectpicker"
                            @change="oralLanguageChange($event)"
                        >
                            <option
                                v-for="option in oralLanguages"
                                :key="`oralLanguage_${option.code}`"
                                :value="option.value"
                                :selected="option.selected"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-3">
                    <label>Written Language</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="flaticon2 flaticon2-world" /></span>
                        </div>
                        <select
                            class="form-control kt-selectpicker"
                            @change="writtenLanguageChange($event)"
                        >
                            <option
                                v-for="option in writtenLanguages"
                                :key="`writtenLanguage_${option.code}`"
                                :value="option.value"
                                :selected="option.selected"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-2 offset-1">
                    <label class="w-100">Resides With Student</label>
                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--brand">
                        <label>
                            <input
                                v-model="guardian.residesWithStudent"
                                type="checkbox"
                            >
                            <span />
                        </label>
                    </span>
                </div>
                <div class="col-12 pt-2">
                    <span class="text-muted">
                        Guardian bio information edited by
                        <template v-if="guardian.etlSourced">
                            ATS RCON Import
                        </template>
                        <template v-else>
                            {{ guardian.author }}
                        </template>
                        <span :title="guardian.updated">{{ guardian.lastEdit }}</span>
                    </span>
                </div>
            </div>

            <div class="form-group row pt-2">
                <h3 class="kt-section__title w-50 ml-0">
                    Email Accounts
                </h3>
                <h3 class="kt-section__title w-50">
                    Phone Numbers
                </h3>
                <div class="col-6">
                    <div
                        v-if="guardian.emailAddresses.filter((e) => !e.deleted).length > 0"
                        class="row"
                    >
                        <div class="col-3">
                            <label>SyncGrades Home</label>
                        </div>
                        <div class="col-9">
                            <label>Email</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <div
                            v-for="(email, idx) in guardian.emailAddresses.filter((e) => !e.deleted)"
                            :key="`email_${idx}`"
                            class="row pb-1"
                        >
                            <div class="col-3">
                                <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--brand">
                                    <label>
                                        <input
                                            v-model="email.enableSyncGradesHome"
                                            type="checkbox"
                                            checked="checked"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <div class="col-9">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class=" flaticon flaticon-email" /></span>
                                    </div>
                                    <input
                                        v-model="email.emailAddress"
                                        type="email"
                                        class="form-control"
                                        :class="{'is-invalid': email.error}"
                                        placeholder="Email address"
                                    >
                                    <div class="input-group-append">
                                        <a
                                            href="#"
                                            class="btn btn-danger btn-icon"
                                            @click.stop.prevent="removeEmailAtIndex(idx)"
                                        ><i class="la la-close" /></a>
                                    </div>
                                    <div
                                        v-if="email.error"
                                        class="invalid-feedback"
                                    >
                                        {{ email.error }}
                                    </div>
                                </div>
                            </div>

                            <span class="text-muted col-12 text-muted col-12 pt-2 pb-2">
                                <template v-if="email.updated">
                                    Email address edited by
                                    <template v-if="email.etlSourced">
                                        ATS RCON Import
                                    </template>
                                    <template v-else>
                                        {{ email.author }}
                                    </template>
                                    <span :title="email.updated">{{ email.lastEdit }}</span>
                                </template>
                            </span>
                        </div>

                        <div class="row pt-3 mr-0">
                            <a
                                href="#"
                                class="btn btn-outline-info btn-sm offset-8 col-4"
                                @click.prevent="addEmail()"
                            >
                                Add Email
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div
                        v-if="guardian.phones.filter((p) => !p.deleted).length > 0"
                        class="row"
                    >
                        <div class="col-3">
                            <label>Type</label>
                        </div>
                        <div class="col-9">
                            <label>Phone Number</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div
                            v-for="(phone, idx) in guardian.phones.filter((p) => !p.deleted)"
                            :key="`phone_${idx}`"
                            class="row pb-3"
                        >
                            <div class="col-3">
                                <select
                                    class="form-control kt-selectpicker"
                                    @change="phoneTypeChange($event, idx)"
                                >
                                    <option
                                        v-for="phoneType in phoneTypes"
                                        :key="`phoneType_${idx}_${phoneType}`"
                                        :value="phoneType"
                                        :selected="phone.phoneType==phoneType"
                                    >
                                        {{ phoneType }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-9">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="la la-phone" /></span>
                                    </div>

                                    <the-mask
                                        v-model="phone.phoneNumber"
                                        type="tel"
                                        mask="(###) ###-####"
                                        class="form-control"
                                        :class="{'is-invalid': phone.error}"
                                        placeholder="Telephone number"
                                    />
                                    <div class="input-group-append">
                                        <a
                                            href="#"
                                            class="btn btn-danger btn-icon"
                                            @click.stop.prevent="removePhoneAtIndex(idx)"
                                        ><i class="la la-close" /></a>
                                    </div>
                                    <div
                                        v-if="phone.error"
                                        class="invalid-feedback"
                                    >
                                        {{ phone.error }}
                                    </div>
                                </div>
                            </div>
                            <span class="text-muted col-12 pt-3">
                                <template v-if="phone.updated">
                                    Phone number edited by
                                    <template v-if="phone.etlSourced">
                                        ATS RCON Import
                                    </template>
                                    <template v-else>
                                        {{ phone.author }}
                                    </template>
                                    <span :title="phone.updated">{{ phone.lastEdit }}</span>
                                </template>
                            </span>
                        </div>
                        <div class="row pt-3 mr-0">
                            <a
                                href="#"
                                class="btn btn-outline-info btn-sm offset-8 col-4"
                                @click.prevent="addPhone()"
                            >
                                Add  Phone
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </template>
    <template #modal-footer>
        <div class="row w-100">
            <div class="col-6">
                <span class="kt-font-danger">{{ error }}</span>
            </div>
            <div class="col-6">
                <button
                    type="button"
                    class="btn btn-success pull-right"
                    data-dismiss="modal"
                    :disabled="!dirty"
                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                    @click="save"
                >
                    Save Guardian
                </button>
                <a
                    v-if="guardian.studentGuardianId != '0' && !guardian.deleted"
                    href="#"
                    class="btn btn-danger pull-right mr-3"
                    data-dismiss="modal"
                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                    @click.prevent="deleteGuardian"
                >
                    Delete Guardian
                </a>
            </div>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';
import deepcopy from 'deepcopy';
import moment from 'moment';
import { TheMask } from 'vue-the-mask';
import Types from '../../store/Types';
import Joi from 'joi';
import { tlds } from '@hapi/tlds';

import * as network from '../../network';
import { decryptGuardian } from '../../store/mixins/studentGuardianMixins';

export default {
    name: 'StudentGuardianModal',
    components: {
        TheMask,
    },
    props: {
        lookup: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        studentEnrollmentId: {
            type: Number,
            required: true,
        },
        editCallback: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            dirty: false,
            error: null,
            guardian: {},
            originalGuardian: {},
            phoneTypes: ['Mobile', 'Home', 'Work'],
            titles: ['Mr.', 'Mrs.', 'Ms.', 'Dr.', null],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            relationCodes() {
                const v = this;
                const relationCodes = v.lookup.relationCodes.map((r) => {
                    const relation = r;
                    relation.selected = v.guardian.relationToStudent == relation.value;
                    relation.text = relation.value;
                    return relation;
                });
                relationCodes.unshift({
                    code: '',
                    value: null,
                    text: '',
                    selected: v.guardian.relationToStudent == null,
                });
                return relationCodes;
            },
            authorizationCodes() {
                const v = this;
                const authorizationCodes = v.lookup.authCodes.map((a) => {
                    const authCode = a;
                    authCode.selected = v.guardian.guardianAuthorization == authCode.value;
                    authCode.text = authCode.value;
                    return authCode;
                });
                return authorizationCodes;
            },
            oralLanguages() {
                const v = this;
                const homeLanguages = deepcopy(v.lookup.homeLanguages);
                const languages = homeLanguages.map((l) => {
                    const language = l;
                    language.selected = Boolean(v.guardian.oralLanguage == language.value);
                    language.text = language.value;
                    return language;
                });
                languages.sort((a, b) => {
                    if (a.value == 'English' || b.value == 'English') return 0;
                    if (a.value < b.value) return -1;
                    if (a.value > b.value) return 1;
                    return 0;
                });
                languages.sort((a, b) => {
                    if (a.value === b.value) return 0;
                    return a.value < b.value ? -1 : 1;
                });

                // push english to top
                const i = languages.findIndex((o) => o.value === 'English');
                if (i > 0) {
                    const eng = languages.splice(i, 1);
                    languages.unshift(eng[0]);
                }

                languages.unshift({
                    code: '',
                    value: null,
                    text: '',
                    selected: v.guardian.oralLanguage == null,
                });
                return languages;
            },
            writtenLanguages() {
                const v = this;
                const homeLanguages = deepcopy(v.lookup.homeLanguages);
                const languages = homeLanguages.map((l) => {
                    const language = l;
                    language.selected = v.guardian.writtenLanguage == language.value;
                    language.text = language.value;
                    return language;
                });

                languages.sort((a, b) => {
                    if (a.value === b.value) return 0;
                    return a.value < b.value ? -1 : 1;
                });

                // push english to top
                const i = languages.findIndex((o) => o.value === 'English');
                if (i > 0) {
                    const eng = languages.splice(i, 1);
                    languages.unshift(eng[0]);
                }

                languages.unshift({
                    code: '',
                    value: null,
                    text: '',
                    selected: v.guardian.writtenLanguage == null,
                });
                return languages;
            },
        }),
    },
    watch: {
        guardian: {
            handler() {
                this.markDirty();
            },
            deep: true,
        },
    },
    methods: {
        open(g) {
            const v = this;
            const guardian = deepcopy(g);
            guardian.guardianFirstNameError = null;
            guardian.guardianLastNameError = null;
            guardian.phones = guardian.phones.map((p) => {
                const phone = p;
                phone.error = null;
                phone.lastEdit = moment(phone.updated).fromNow();
                return phone;
            }).filter((p) => !p.deleted);

            guardian.emailAddresses = guardian.emailAddresses.map((e) => {
                const email = e;
                email.error = null;
                email.lastEdit = moment(email.updated).fromNow();
                return email;
            }).filter((e) => !e.deleted);
            guardian.lastEdit = moment(guardian.updated).fromNow();

            v.guardian = guardian;

            v.originalGuardian = deepcopy(g);
            v.$refs['student-guardian-modal'].show();

            setTimeout(() => {
                v.dirty = Boolean(v.guardian.studentGuardianId);
                v.error = null;
                v.$refs.firstName.focus();
            }, 100);
        },
        markDirty() {
            const v = this;
            if (v.guardian.studentGuardianId) {
                v.dirty = true;
            }
        },
        addEmail() {
            const v = this;
            const email = {
                emailAddress: '',
                enableSyncGradesHome: false,
                etlSourced: false,
                error: null,
            };
            v.guardian.emailAddresses.push(email);
        },
        removeEmailAtIndex(idx) {
            const v = this;
            v.guardian.emailAddresses[idx].deleted = true;
        },
        addPhone() {
            const v = this;
            const phone = {
                phoneNumber: '',
                phoneType: 'Mobile',
                enableSyncGradesHome: false,
                etlSourced: false,
                error: null,
            };
            v.guardian.phones.push(phone);
        },
        removePhoneAtIndex(idx) {
            const v = this;
            v.guardian.phones[idx].deleted = true;
        },
        relationChange(event) {
            const v = this;
            const relationCode = event.target.value;
            const match = v.relationCodes.find((r) => r.value === relationCode);
            v.guardian.relationToStudent = match ? match.value : null;
        },
        authorizationChange(event) {
            const v = this;
            const authorizationCode = event.target.value;
            const match = v.authorizationCodes.find((r) => r.value === authorizationCode);
            v.guardian.guardianAuthorization = match ? match.value : null;
        },
        oralLanguageChange(event) {
            const v = this;
            const language = event.target.value;
            const match = v.oralLanguages.find((l) => l.value === language);
            v.guardian.oralLanguage = match ? match.value : null;
        },
        writtenLanguageChange(event) {
            const v = this;
            const language = event.target.value;
            const match = v.writtenLanguages.find((l) => l.value === language);
            v.guardian.writtenLanguage = match ? match.value : null;
        },
        phoneTypeChange(event, idx) {
            const v = this;
            const phoneType = event.target.value;
            const phone = deepcopy(v.guardian.phones[idx]);
            phone.phoneType = phoneType;
            v.$set(v.guardian.phones, idx, phone);
        },
        guardianTitleChange(event) {
            const v = this;
            const title = event.target.value;
            v.guardian.guardianTitle = title;
        },
        deleteGuardian() {
            const v = this;
            if (!v.dirty) return;
            if (v.saving) return;
            v.validate();
            if (v.error) return;

            const { schoolTermId, schoolId } = v.user.school;
            const { studentEnrollmentId } = v;
            const { studentGuardianId } = v.guardian;
            const { schoolTermEncryptionKey } = this.$store.state.database;
            v.saving = true;
            network.guardians.deleteStudentGuardians({
                url: {
                    schoolId, schoolTermId, studentEnrollmentId, studentGuardianId,
                },
                body: {
                    schoolTermEncryptionKey,
                },
            }, (err, resp) => {
                if (err) {
                    // console.error(err);
                    v.saving = false;
                    v.showError('Error saving guardian');
                    return;
                }
                v.saving = false;
                v.editCallback(resp.studentGuardians);
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GUARDIANS, resp.studentGuardians);
                v.$refs['student-guardian-modal'].hide();
            });
        },
        save() {
            const v = this;
            if (!v.dirty) return;
            if (v.saving) return;
            v.validate();
            if (v.error) return;

            const { schoolTermId, schoolId } = v.user.school;
            const { studentEnrollmentId } = v;
            const { schoolTermEncryptionKey } = this.$store.state.database;

            const guardian = {
                studentGuardianId: v.guardian.studentGuardianId,
                guardianTitle: v.guardian.guardianTitle,
                guardianFirstName: v.guardian.guardianFirstName,
                guardianLastName: v.guardian.guardianLastName,
                residesWithStudent: v.guardian.residesWithStudent,
                relationToStudent: v.guardian.relationToStudent,
                guardianAuthorization: v.guardian.guardianAuthorization,
                oralLanguage: v.guardian.oralLanguage,
                writtenLanguage: v.guardian.writtenLanguage,
                phones: v.guardian.phones.map((phone) => ({
                    phoneNumber: phone.phoneNumber,
                    phoneType: phone.phoneType,
                    deleted: phone.deleted || false,
                })),
                emailAddresses: v.guardian.emailAddresses.map((email) => ({
                    emailAddress: email.emailAddress,
                    enableSyncGradesHome: email.enableSyncGradesHome,
                    deleted: email.deleted || false,
                })),
            };
            v.saving = true;
            network.guardians.saveStudentGuardians({ url: { schoolId, schoolTermId, studentEnrollmentId }, body: { guardian, schoolTermEncryptionKey } }, (err, resp) => {
                if (err) {
                    // console.error(err);
                    v.saving = false;
                    v.showError('Error saving guardian');
                    return;
                }
                v.saving = false;
                const guardians = resp.studentGuardians.map((g) => decryptGuardian(g, schoolTermEncryptionKey));
                v.$store.commit(Types.mutations.SET_DB_STUDENT_GUARDIANS, guardians);
                v.editCallback(guardians);
                v.$refs['student-guardian-modal'].hide();
            });
        },
        validate() {
            const v = this;
            // clear errors
            v.error = null;
            v.guardian.guardianLastNameError = null;
            v.guardian.guardianFirstNameError = null;
            const { invalidDomains } = v.lookup;
            // validate
            if ((v.guardian.guardianFirstName || '').length < 2) {
                v.guardian.guardianFirstNameError = 'Please enter a First Name';
                v.error = 'Please enter a First Name';
            }
            if ((v.guardian.guardianLastName || '').length < 2) {
                v.guardian.guardianLastNameError = 'Please enter a Last Name';
                v.error = 'Please enter a Last Name';
            }

            v.guardian.phones = v.guardian.phones.map((p) => {
                const phone = p;
                const valid = (phone.phoneNumber || '').length === 10;
                if (!valid) v.error = 'Please enter a valid 10 digit number';
                phone.error = valid ? null : 'Please enter a valid 10 digit number';
                return phone;
            });
            v.guardian.emailAddresses = v.guardian.emailAddresses.map((e) => {
                const email = e;
                const emailAddress = (email.emailAddress || '').toLowerCase().trim();
                const valid = Joi.attempt(emailAddress, Joi.string().email(({ tlds: { allow: tlds } })).trim());
                if (!valid) v.error = 'Please enter a valid email address';
                email.error = valid ? null : 'Please enter a valid email address';

                const hasErrors = invalidDomains.some((d) => emailAddress.endsWith(d));
                if (!email.error && hasErrors) {
                    email.error = `The following domains are not allowed: ${invalidDomains.join(', ')}`;
                    v.error = email.error;
                }
                return email;
            });
        },
    },
};

</script>

<style scoped>
h3.kt-section__title {
    font-weight: 500;
    font-size: 1.3rem;
    color: #48465b;
    padding-bottom: 22px;
}
</style>
