import async from 'async';
import moment from 'moment';
import numeral from 'numeral';
import XLSX from '@sheet/crypto/dist/xlsx.full.min';
import { decryptSchoolTermEncryptionKey } from '../cipher';

import * as network from '../../network';

/**
 * @param {Array} files
 * @param {Array} dataSystems
 * @param {Object} user
 * @param {Function} callback
 */
function process(files, dataSystems, user, markingPeriods, callback) {
    async.eachOfSeries(files, (f, i, next) => {
        const file = f;
        file.fileId = `file_${new Date().getTime()}`;
        processFile(file, dataSystems, user, markingPeriods, (err) => {
            if (err) return next(err);
            if (file.error) return next(file.error);
            return next(err);
        });
    }, (err) => {
        if (err) return callback(err);
        callback(err);
    });
}

function processFile(file, dataSystems, user, markingPeriods, callback) {
    const fileName = file.name.toLowerCase();
    if (!fileName.endsWith('.xls')) return callback(new Error('Bad extension'));
    const author = `${user.lastName}, ${user.firstName}`;

    const { schoolId, schoolTermId, externalDistrictId } = user.school;
    const stek = window.sessionStorage.getItem(`stek_${schoolTermId}`);

    async.auto({
        schoolTermEncryptionKey(next) {
            if (!stek) return next(null, null);
            decryptSchoolTermEncryptionKey(user.school, next);
        },
        data(next) {
            const reader = new FileReader();
            reader.onload = function (event) {
                // @ts-ignore
                const data = new Uint8Array(event.target.result);
                next(null, data);
            };
            reader.onerror = function () {
                return next(new Error('Error reading file'));
            };
            reader.readAsArrayBuffer(file);
        },
        workbook: ['data', 'schoolTermEncryptionKey', function (results, next) {
            const { data } = results;
            let workbook;
            try {
                workbook = XLSX.read(data, { type: 'array', cellStyles: true });
            } catch (e) {
                return next(e);
            }
            return next(null, workbook);
        }],
        properties: ['workbook', function (results, next) {
            const { workbook } = results;
            if (workbook.SheetNames.length !== 4) return next(new Error('Unknown XLS file'));
            if (workbook.SheetNames[1] !== 'Grade_Data') return next(new Error('Unknown XLS sheet'));
            const isDemoSchool = externalDistrictId == 'DEMO';

            const worksheet = workbook.Sheets.Main;
            const schoolCell = worksheet.F11;
            const school = (schoolCell ? schoolCell.v : undefined);
            if (school != user.school.externalSchoolId) {
                return next(new Error(`Invalid school for HS EGG File, found ${school} expected ${user.school.externalSchoolId}`));
            }
            const yearCell = worksheet.F12;
            const year = (yearCell ? yearCell.v : undefined);
            if (year != user.school.schoolYear && !isDemoSchool) {
                return next(new Error(`Invalid school year for HS EGG File, found ${year} expected ${user.school.schoolYear}`));
            }
            const termCell = worksheet.F13;
            const term = (termCell ? termCell.v : undefined);
            if (term != user.school.schoolTerm && !isDemoSchool) {
                return next(new Error(`Invalid school term for HS EGG File, found ${term} expected ${user.school.schoolTerm}`));
            }
            const mpCell = worksheet.F14;
            const markingPeriod = (mpCell ? mpCell.v : '').toString();

            if (markingPeriods.indexOf(markingPeriod) == -1) {
                return next(new Error(`Invalid marking period for HS EGG File, found ${markingPeriod} expected ${markingPeriods.join(', ')}`));
            }

            const internalName = `highSchoolReportCardExcel_mp${markingPeriod}`;
            return next(null, {
                internalName,
                markingPeriod,
            });
        }],
        syncFile: ['properties', function (results, next) {
            const { internalName } = results.properties;
            let syncFile = null;
            dataSystems.forEach((dataSystem) => {
                const found = dataSystem.files.find((f) => f.syncFile.internalName == internalName) || null;
                if (found) syncFile = found;
            });
            if (!syncFile) return next(new Error('File type not found'));
            return next(null, syncFile);
        }],
        comments: ['syncFile', function (results, next) {
            const wb = results.workbook;
            const { schoolTermEncryptionKey } = results;
            const dataType = 'comments';
            window.syncGrades.log(`Submitting report card ${dataType}`, 'info', 'reportCards');
            const rows = XLSX.utils
                .sheet_to_json(wb.Sheets.Comments)
                .map((row) => ({
                    commentCode: (row.ID || '').toString(),
                    commentDescription: row.Comment || null,
                    commentType: row['Comment Type'] || null,
                    commentCategory: row.Category || null,
                    commentIndicator: row.Indicator || null,
                    commentLevel: row.Level || null,
                    schoolId,
                    author,
                    deleted: false,
                }));

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    dataType,
                    markingPeriodName: results.properties.markingPeriod,
                },
                body: {
                    schoolTermEncryptionKey,
                    dataSource: { dataType, rows },
                },
            };
            network.reportCard.uploadDataSource(params, next);
        }],
        marks: ['comments', function (results, next) {
            // merge exam and course marks
            const wb = results.workbook;
            const { schoolTermEncryptionKey } = results;
            const validMarks = XLSX.utils
                .sheet_to_json(wb.Sheets.Lookup, { range: 'B3:E1000' })
                .map((row) => ({
                    mark: (row.Mark || '').toString(),
                    numericEquivalent: row['Numeric Equivalent'] || null,
                    passing: row.Passing == 'Yes' ? true : row.Passing == 'No' ? false : null,
                    markDescription: (row.Description || '').trim() || null,
                    schoolId,
                    author,
                    deleted: false,
                })).filter((row) => row.mark);

            const validExamMarks = XLSX.utils
                .sheet_to_json(wb.Sheets.Lookup, { range: 'G3:J1000' })
                .map((row) => ({
                    mark: (row.Mark || '').toString(),
                    numericEquivalent: row['Numeric Equivalent'] || null,
                    passing: row.Passing == 'Yes' ? true : row.Passing == 'No' ? false : null,
                    markDescription: (row.Description || '').trim() || null,
                    schoolId,
                    author,
                    deleted: false,
                })).filter((row) => row.mark);

            const rows = [];
            validMarks.forEach((c) => {
                const courseMark = c;
                const examMark = validExamMarks.find((e) => e.mark == courseMark.mark);
                courseMark.isCourseMark = true;
                courseMark.isExamMark = Boolean(examMark);
                rows.push(courseMark);
            });
            validExamMarks.forEach((e) => {
                const examMark = e;
                const exists = rows.find((c) => c.mark == examMark.mark);
                if (!exists) {
                    examMark.isCourseMark = false;
                    examMark.isExamMark = true;
                    rows.push(examMark);
                }
            });
            const dataType = 'marks';
            window.syncGrades.log(`Submitting report card ${dataType}`, 'info', 'reportCards');
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    dataType,
                    markingPeriodName: results.properties.markingPeriod,
                },
                body: {
                    schoolTermEncryptionKey,
                    dataSource: { dataType, rows },
                },
            };
            network.reportCard.uploadDataSource(params, next);
        }],
        grades: ['marks', function (results, next) {
            const wb = results.workbook;
            const { schoolTermEncryptionKey } = results;

            const dataType = 'gradeData';
            window.syncGrades.log(`Submitting report card ${dataType}`, 'info', 'reportCards');
            const rows = XLSX.utils
                .sheet_to_json(wb.Sheets.Grade_Data)
                .map((row) => ({
                    extStudentId: (row.StudentID || '').toString(),
                    extCourseSectionId: `${row.Course}-${row.Sec}`,
                    mark: (row.Mark || '').toUpperCase() || null,
                    examMark: (row.Exam || '').toUpperCase() || null,
                    conduct: (row.Conduct || '').toUpperCase() || null,
                    comment1: row.C1 || null,
                    comment2: row.C2 || null,
                    comment3: row.C3 || null,
                    absent: row.Absent || null,
                    level: row.Level || null,
                    isFinal: row.Final || null,
                    narrative: row.Narrative || null,
                }));

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    dataType,
                    markingPeriodName: results.properties.markingPeriod,
                },
                body: {
                    schoolTermEncryptionKey,
                    dataSource: { dataType, rows },
                },
            };
            network.reportCard.uploadDataSource(params, next);
        }],
        uploadFile: ['grades', function (results, next) {
            const { syncFile } = results.syncFile;
            const params = { localFile: file, syncFile };
            uploadFile(params, user, next);
        }],
    }, (err, results) => {
        if (err) {
            const f = file;
            f.err = `${err}`;
            return uploadError(f, user, (err2, errFile) => {
                callback(err2 || errFile.fileError);
            });
        }
        callback(err, results);
    });
}

/** UPLOAD TO AWS + SYNCFILE UPLOAD
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadFile(file, user, callback) {
    const { localFile, syncFile } = file;
    const { internalName } = syncFile;
    const { schoolId, schoolTermId } = user.school;

    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'data-uploads',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, syncFile, next);
        }],
        syncFileUploads: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');
            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId, internalName },
                body: {
                    bucketPath,
                    fileSize,
                    fileLastModified,
                },
            };
            network.storage.saveSyncFileDetails(params, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} localFile
 * @param {Object} user
 * @param {Function} callback
 */
function uploadError(localFile, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'error-bin',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };
            network.storage.getAWSFormDetails(params, (err, res) => {
                if (err) return next(err);
                next(null, res);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, null, next);
        }],
        saveError: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');
            const fileName = localFile.name;
            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId },
                body: {
                    fileName,
                    bucketPath,
                    fileSize,
                    fileLastModified,
                    fileError: localFile.err ? localFile.err : 'Unknown file',
                },
            };
            network.storage.saveSyncFileError(params, (err, res) => {
                if (err) return next(err);
                next(err, res.file);
            });
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results.saveError);
    });
}

/** ONLY UPLOAD TO AWS BUCKET AND RETURN BUCKET PATH
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadToAWS(fileType, file, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType,
                },
                body: {
                    fileParameters: {
                        fileName: file.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, file, false, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} awsParameters
 * @param {Object} localFile
 * @param {Object} syncFile
 * @param {Function} callback
 */
function awsUpload(awsParameters, localFile, syncFile, callback) {
    const formData = new FormData();
    Object.keys(awsParameters.fields).forEach((name) => {
        formData.append(name, awsParameters.fields[name]);
    });

    const fileProgress = {
        status: 'Preparing',
        percentComplete: 0,
        stringPercentage: '0%',
        error: null,
    };

    formData.append('file', localFile);
    // presentation.bucketPath = awsParameters.fields.key;

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
            fileProgress.status = 'Uploading';
            fileProgress.percentComplete = Math.round((evt.loaded * 100) / evt.total);
            fileProgress.stringPercentage = (`${fileProgress.percentComplete.toString()}%`);
        }
    }, false);

    xhr.addEventListener('load', (evt) => {
        // @ts-ignore
        if (evt.target.status >= 200 && evt.target.status < 300) {
            fileProgress.error = null;
            fileProgress.status = 'Uploaded';
            fileProgress.percentComplete = 100;
            fileProgress.stringPercentage = '100%';
        } else {
            fileProgress.status = 'Error';
            fileProgress.error = 'Upload failed';
            fileProgress.stringPercentage = '0%';
        }
        return callback();
    }, false);

    xhr.addEventListener('error', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        return callback();
    }, false);

    xhr.addEventListener('abort', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        return callback();
    }, false);

    const { bucket } = awsParameters.fields;
    xhr.open('POST', `https://s3.amazonaws.com/${bucket}`, true);
    xhr.send(formData);
}

export {
    process,
    uploadFile,
    uploadError,
    uploadToAWS,
    awsUpload,
};
