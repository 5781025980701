var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "student-guardian-modal",
    attrs: { size: "xl", variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _vm.guardian.studentGuardianId
              ? _c(
                  "form",
                  {
                    staticClass: "kt-portlet__body p-3",
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.save()
                      },
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("h3", { staticClass: "kt-section__title col-12" }, [
                        _vm._v(" Guardian Biographical Information "),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("label", {}, [_vm._v("First Name *")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c(
                              "select",
                              {
                                staticClass: "form-control kt-selectpicker",
                                on: {
                                  change: function ($event) {
                                    return _vm.guardianTitleChange($event)
                                  },
                                },
                              },
                              _vm._l(_vm.titles, function (title) {
                                return _c(
                                  "option",
                                  {
                                    key: `title_${title}`,
                                    domProps: {
                                      value: title,
                                      selected:
                                        title == _vm.guardian.guardianTitle,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(title) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.guardian.guardianFirstName,
                                expression: "guardian.guardianFirstName",
                              },
                            ],
                            ref: "firstName",
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.guardian.guardianFirstNameError,
                            },
                            attrs: {
                              type: "text",
                              disabled: _vm.guardian.studentGuardianId != "0",
                              placeholder: "Guardian First Name",
                              autofocus: "",
                            },
                            domProps: { value: _vm.guardian.guardianFirstName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.guardian,
                                  "guardianFirstName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.guardian.guardianFirstNameError
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.guardian.guardianFirstNameError
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("label", [_vm._v("Last Name *")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", { staticClass: "la la-user" }),
                            ]),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.guardian.guardianLastName,
                                expression: "guardian.guardianLastName",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.guardian.guardianLastNameError,
                            },
                            attrs: {
                              type: "text",
                              disabled: _vm.guardian.studentGuardianId != "0",
                              placeholder: "Guardian Last Name",
                            },
                            domProps: { value: _vm.guardian.guardianLastName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.guardian,
                                  "guardianLastName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm.guardian.guardianLastNameError
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.guardian.guardianLastNameError) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("label", {}, [_vm._v("Authorization *")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", {
                                staticClass: "flaticon flaticon-security",
                              }),
                            ]),
                          ]),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              on: {
                                change: function ($event) {
                                  return _vm.authorizationChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.authorizationCodes, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `authorizationCode_${option.code}`,
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _c("label", [_vm._v("Relation To Student")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", { staticClass: "la la-users" }),
                            ]),
                          ]),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              on: {
                                change: function ($event) {
                                  return _vm.relationChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.relationCodes, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `relation_${option.code}`,
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("label", {}, [_vm._v("Oral Language")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", {
                                staticClass: "flaticon2 flaticon2-world",
                              }),
                            ]),
                          ]),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              on: {
                                change: function ($event) {
                                  return _vm.oralLanguageChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.oralLanguages, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `oralLanguage_${option.code}`,
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("label", [_vm._v("Written Language")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", {
                                staticClass: "flaticon2 flaticon2-world",
                              }),
                            ]),
                          ]),
                          _c(
                            "select",
                            {
                              staticClass: "form-control kt-selectpicker",
                              on: {
                                change: function ($event) {
                                  return _vm.writtenLanguageChange($event)
                                },
                              },
                            },
                            _vm._l(_vm.writtenLanguages, function (option) {
                              return _c(
                                "option",
                                {
                                  key: `writtenLanguage_${option.code}`,
                                  domProps: {
                                    value: option.value,
                                    selected: option.selected,
                                  },
                                },
                                [_vm._v(" " + _vm._s(option.text) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2 offset-1" }, [
                        _c("label", { staticClass: "w-100" }, [
                          _vm._v("Resides With Student"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass:
                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--brand",
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.guardian.residesWithStudent,
                                    expression: "guardian.residesWithStudent",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.guardian.residesWithStudent
                                  )
                                    ? _vm._i(
                                        _vm.guardian.residesWithStudent,
                                        null
                                      ) > -1
                                    : _vm.guardian.residesWithStudent,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.guardian.residesWithStudent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.guardian,
                                            "residesWithStudent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.guardian,
                                            "residesWithStudent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.guardian,
                                        "residesWithStudent",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _c("span"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "col-12 pt-2" }, [
                        _c(
                          "span",
                          { staticClass: "text-muted" },
                          [
                            _vm._v(" Guardian bio information edited by "),
                            _vm.guardian.etlSourced
                              ? [_vm._v(" ATS RCON Import ")]
                              : [
                                  _vm._v(
                                    " " + _vm._s(_vm.guardian.author) + " "
                                  ),
                                ],
                            _c(
                              "span",
                              { attrs: { title: _vm.guardian.updated } },
                              [_vm._v(_vm._s(_vm.guardian.lastEdit))]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row pt-2" }, [
                      _c("h3", { staticClass: "kt-section__title w-50 ml-0" }, [
                        _vm._v(" Email Accounts "),
                      ]),
                      _c("h3", { staticClass: "kt-section__title w-50" }, [
                        _vm._v(" Phone Numbers "),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _vm.guardian.emailAddresses.filter((e) => !e.deleted)
                          .length > 0
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-3" }, [
                                _c("label", [_vm._v("SyncGrades Home")]),
                              ]),
                              _c("div", { staticClass: "col-9" }, [
                                _c("label", [_vm._v("Email")]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._l(
                              _vm.guardian.emailAddresses.filter(
                                (e) => !e.deleted
                              ),
                              function (email, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: `email_${idx}`,
                                    staticClass: "row pb-1",
                                  },
                                  [
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-switch kt-switch--outline kt-switch--icon kt-switch--brand",
                                        },
                                        [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    email.enableSyncGradesHome,
                                                  expression:
                                                    "email.enableSyncGradesHome",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                checked: "checked",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  email.enableSyncGradesHome
                                                )
                                                  ? _vm._i(
                                                      email.enableSyncGradesHome,
                                                      null
                                                    ) > -1
                                                  : email.enableSyncGradesHome,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      email.enableSyncGradesHome,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          email,
                                                          "enableSyncGradesHome",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          email,
                                                          "enableSyncGradesHome",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      email,
                                                      "enableSyncGradesHome",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col-9" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group-prepend",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "flaticon flaticon-email",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: email.emailAddress,
                                                expression:
                                                  "email.emailAddress",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": email.error,
                                            },
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email address",
                                            },
                                            domProps: {
                                              value: email.emailAddress,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  email,
                                                  "emailAddress",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-append",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-danger btn-icon",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeEmailAtIndex(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "la la-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          email.error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(email.error) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-muted col-12 text-muted col-12 pt-2 pb-2",
                                      },
                                      [
                                        email.updated
                                          ? [
                                              _vm._v(
                                                " Email address edited by "
                                              ),
                                              email.etlSourced
                                                ? [_vm._v(" ATS RCON Import ")]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(email.author) +
                                                        " "
                                                    ),
                                                  ],
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: email.updated,
                                                  },
                                                },
                                                [_vm._v(_vm._s(email.lastEdit))]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c("div", { staticClass: "row pt-3 mr-0" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-outline-info btn-sm offset-8 col-4",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addEmail()
                                    },
                                  },
                                },
                                [_vm._v(" Add Email ")]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _vm.guardian.phones.filter((p) => !p.deleted).length > 0
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-3" }, [
                                _c("label", [_vm._v("Type")]),
                              ]),
                              _c("div", { staticClass: "col-9" }, [
                                _c("label", [_vm._v("Phone Number")]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._l(
                              _vm.guardian.phones.filter((p) => !p.deleted),
                              function (phone, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: `phone_${idx}`,
                                    staticClass: "row pb-3",
                                  },
                                  [
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "select",
                                        {
                                          staticClass:
                                            "form-control kt-selectpicker",
                                          on: {
                                            change: function ($event) {
                                              return _vm.phoneTypeChange(
                                                $event,
                                                idx
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.phoneTypes,
                                          function (phoneType) {
                                            return _c(
                                              "option",
                                              {
                                                key: `phoneType_${idx}_${phoneType}`,
                                                domProps: {
                                                  value: phoneType,
                                                  selected:
                                                    phone.phoneType ==
                                                    phoneType,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(phoneType) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col-9" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group-prepend",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "la la-phone",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("the-mask", {
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": phone.error,
                                            },
                                            attrs: {
                                              type: "tel",
                                              mask: "(###) ###-####",
                                              placeholder: "Telephone number",
                                            },
                                            model: {
                                              value: phone.phoneNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  phone,
                                                  "phoneNumber",
                                                  $$v
                                                )
                                              },
                                              expression: "phone.phoneNumber",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-append",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn btn-danger btn-icon",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removePhoneAtIndex(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "la la-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          phone.error
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(phone.error) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "text-muted col-12 pt-3" },
                                      [
                                        phone.updated
                                          ? [
                                              _vm._v(
                                                " Phone number edited by "
                                              ),
                                              phone.etlSourced
                                                ? [_vm._v(" ATS RCON Import ")]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(phone.author) +
                                                        " "
                                                    ),
                                                  ],
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title: phone.updated,
                                                  },
                                                },
                                                [_vm._v(_vm._s(phone.lastEdit))]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c("div", { staticClass: "row pt-3 mr-0" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-outline-info btn-sm offset-8 col-4",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addPhone()
                                    },
                                  },
                                },
                                [_vm._v(" Add Phone ")]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "row w-100" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("span", { staticClass: "kt-font-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
              ]),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success pull-right",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                    },
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      disabled: !_vm.dirty,
                    },
                    on: { click: _vm.save },
                  },
                  [_vm._v(" Save Guardian ")]
                ),
                _vm.guardian.studentGuardianId != "0" && !_vm.guardian.deleted
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-danger pull-right mr-3",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: { href: "#", "data-dismiss": "modal" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.deleteGuardian.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" Delete Guardian ")]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }